import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import { PostData } from '../datas/post';
import { TagIcons } from '../components/tag';

const BlogPostTemplate = ({ data, pageContext }: {
  data: DataType;
  pageContext: {
    slug: string;
    previous: PostData;
    next: PostData;
  };
}): JSX.Element => {
  const post = data.markdownRemark;
  const { previous, next } = pageContext;

  return (
    <Layout seo={{ title: post.frontmatter.title, description: post.excerpt }}>
      <div className="container">
        <article>
          <header>
            <h1 className="title">{post.frontmatter.title}</h1>

            <div className="level">
              <div className="level-left">
                <p className="has-text-grey subtitle has-text-weight-bold">{post.frontmatter.date}</p>
              </div>
              <div className="level-right">
                <TagIcons tags={post.frontmatter.tags} />
              </div>
            </div>
          </header>
          <section className="mt-6">
            <div
              dangerouslySetInnerHTML={{ __html: post.html }}
            />
          </section>
          <hr />
        </article>

        <nav>
          <ul
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              listStyle: 'none',
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  {`← ${previous.frontmatter.title}`}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {`${next.frontmatter.title} →`}
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </div>
    </Layout>
  );
};

export default BlogPostTemplate;

type DataType = {
  site: {
    siteMetadata: {
      title: string;
    };
  };
  markdownRemark: {
    id: string;
    excerpt: string;
    html: string;
    frontmatter: {
      date: string;
      title: string;
      tags: string[];
    };
  };
};

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        tags
      }
    }
  }
`;
